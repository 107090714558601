(function () {
    'use strict';

    /**
     ToolLibraryService factory
     STATELESS and IMMUTABLE, please leave like that
     produces models prototyped with delete/update functionality.
     */
    angular.module('aerosApp').factory('ToolLibraryService', ToolLibraryService);

    ToolLibraryService.$inject = ['aerosApi', '$q', '$http', 'ToolsDefinitionService', 'ToolService'];
    function ToolLibraryService(aerosApi, $q, $http, ToolsDefinitionService, ToolService) {
        var ModelPrototype = {
            destroy: deleteToolLibrary,
            update: updateToolLibraryBySelf
        };

        var format = ToolsDefinitionService.format;

        return {
            update: updateToolLibrary,
            fetch: getToolLibraries,
            create: createToolLibrary
        };

        function getToolLibraries(orgId, toolType) {
            return getAndRefactor(orgId, toolType).then(function (toolLibs) {
                var libsObj = {toolType: toolType};
                libsObj[toolType] = toolLibs;
                return libsObj;
            });
        }

        function getAndRefactor(orgId, toolType) {
            return $q.all({
                defs: ToolsDefinitionService.fetch(),
                tools: aerosApi.genericToolLibraryAPI.list(toolType.toLowerCase(), orgId).then(function (res) {
                    return res.data;
                })
            }).then(process);

            function process(res) {
                var groups = _.map(res.defs[toolType].groups, 'id');
                return _.chain(res.tools).map(format.removeJsonPrefixes).map(format.nested2flat).map(format.modelizeItem(ModelPrototype, {toolType: toolType})).value();
            }
        }

        function createToolLibrary(toolType, orgId, data) {
            return aerosApi.genericToolLibraryAPI.post(toolType.toLowerCase(), orgId,
                wrapToolLibraryObject(toolType, data));
        }

        function updateToolLibrary(toolType, orgId, data) {
            return aerosApi.genericToolLibraryAPI.update(toolType.toLowerCase(), orgId, data.uuid,
                wrapToolLibraryObject(toolType, data));
        }

        function deleteToolLibrary() {
            return aerosApi.genericToolLibraryAPI.delete(this.toolType.toLowerCase(), this.organizationId, this.uuid);
        }

        function updateToolLibraryBySelf() {
            return aerosApi.genericToolLibraryAPI.update(this.toolType.toLowerCase(), this.orgId, this.id, wrapToolLibraryObject(this.toolType, this));
        }

        function wrapToolLibraryObject(toolType, data) {

            switch (toolType.toLowerCase()) {
                case 'otdr':
                default:
                    return {
                        tool: format.objToArr(format.flat2nested(data), ToolsDefinitionService.fetch)
                    }
            }

        }
    }

}());
